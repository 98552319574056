import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 110, height: 75, ...sx }}>
      
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 1000.000000 600.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)" fill={PRIMARY_MAIN} stroke="none">
          <path
            fill={PRIMARY_LIGHT}
            d="M7003 5935 c-39 -17 -53 -42 -53 -93 0 -29 7 -46 26 -66 15 -14 31
-26 35 -26 5 0 9 -37 9 -82 l0 -82 80 -82 80 -82 0 -229 0 -229 60 -59 60 -59
0 -439 0 -438 -55 48 -55 48 0 63 c0 47 3 62 14 62 22 0 56 49 56 81 0 44 -38
81 -84 81 -20 0 -40 -3 -43 -6 -8 -7 -233 203 -233 218 0 7 14 22 30 34 25 19
30 30 30 62 0 32 -5 43 -30 62 l-31 22 2 138 1 138 -80 88 c-44 48 -92 98
-106 111 -24 23 -26 31 -26 107 l0 83 -41 39 c-30 28 -42 48 -44 71 -3 40 -20
59 -62 67 -26 5 -37 1 -57 -19 -33 -33 -33 -71 -1 -102 27 -28 62 -32 90 -11
17 12 23 10 57 -18 l38 -31 0 -85 0 -85 105 -105 105 -105 0 -107 0 -108 -27
0 c-19 0 -43 15 -78 48 l-50 47 44 -47 c42 -45 43 -46 20 -51 -13 -2 2 -5 34
-6 55 -1 57 -2 57 -28 0 -21 -8 -33 -30 -45 -57 -32 -58 -98 -2 -133 17 -11
31 -27 32 -36 0 -8 51 -63 114 -122 111 -104 114 -108 104 -135 -16 -42 -2
-80 37 -104 34 -19 35 -22 35 -81 0 -58 2 -61 40 -94 l40 -35 0 -688 0 -689
-65 -58 c-57 -51 -65 -63 -65 -93 0 -32 -12 -47 -110 -145 l-110 -110 0 -107
c0 -69 -4 -108 -10 -108 -20 0 -60 -49 -60 -74 0 -17 -15 -37 -47 -65 -42 -36
-54 -41 -98 -41 -47 0 -53 -3 -107 -57 l-58 -57 0 -86 0 -86 -43 -42 c-40 -39
-46 -42 -69 -31 -34 15 -78 -5 -96 -44 -38 -78 68 -146 128 -82 18 20 22 32
17 64 -4 36 -1 42 39 82 l44 43 0 86 0 86 53 52 c49 49 56 52 102 52 44 0 53
4 91 41 l42 42 28 -22 c58 -46 134 -12 134 60 0 36 -32 79 -58 79 -9 0 -12 30
-12 103 l0 102 100 100 100 100 0 -96 0 -96 -42 -6 c-118 -16 -119 -178 -2
-194 76 -10 134 70 100 138 -13 25 -16 66 -16 183 l0 152 45 44 45 44 0 -300
0 -299 -100 -100 -100 -100 0 -161 0 -161 -25 -11 c-28 -13 -43 -57 -28 -81 5
-9 22 -23 36 -31 24 -13 28 -13 54 6 38 28 41 67 9 98 -26 23 -26 25 -26 177
l0 154 90 89 c78 78 90 86 90 65 0 -17 20 -44 65 -89 l65 -64 0 -70 0 -70 -60
-61 -61 -61 1 -205 0 -204 -30 -17 c-78 -45 -25 -156 64 -134 58 14 64 113 10
138 l-24 11 0 201 0 202 50 49 50 49 0 -141 0 -142 80 -84 80 -85 0 -66 c0
-62 -1 -66 -29 -81 -67 -34 -81 -103 -32 -157 24 -27 36 -33 71 -33 100 0 137
134 50 183 -29 17 -30 19 -30 97 l0 80 -75 82 -75 81 0 229 0 229 -65 64 -65
64 0 434 c0 489 -8 449 78 368 39 -37 42 -44 42 -92 0 -49 -2 -53 -34 -72 -85
-50 -30 -179 64 -152 24 7 38 -3 141 -97 63 -57 115 -110 117 -118 2 -7 -9
-19 -25 -27 -54 -26 -56 -106 -3 -136 l30 -17 -2 -143 -1 -144 107 -107 106
-106 0 -87 0 -87 40 -32 c32 -26 40 -39 40 -65 0 -23 8 -38 26 -53 34 -26 54
-26 88 0 64 50 -1 158 -72 120 -29 -16 -37 -14 -71 15 -30 25 -31 28 -31 113
l0 86 -105 105 -105 105 0 107 0 108 52 0 c52 0 53 -1 138 -83 l85 -82 -85 88
-85 87 -52 0 c-51 0 -53 1 -53 28 0 19 8 32 30 44 52 29 52 109 0 135 -14 7
-27 21 -30 31 -3 10 -58 67 -123 127 -80 74 -114 112 -107 119 19 19 12 81
-13 104 -13 12 -29 22 -35 22 -8 0 -12 21 -12 63 0 63 -1 65 -45 106 l-45 42
0 677 0 678 70 69 c60 60 70 74 70 105 0 32 11 47 110 145 l110 110 0 98 c0
97 0 99 30 124 17 13 30 35 30 47 0 13 20 41 48 69 44 43 52 47 97 47 47 1 54
5 108 55 l57 54 0 88 0 89 43 42 c40 40 45 42 74 31 78 -27 137 68 78 126 -32
33 -78 33 -112 -1 -22 -22 -24 -31 -19 -67 6 -40 4 -45 -39 -87 l-45 -44 0
-87 0 -87 -52 -46 c-47 -41 -58 -46 -102 -46 -45 0 -54 -4 -93 -42 l-43 -42
-23 22 c-75 71 -186 -40 -112 -113 13 -14 31 -25 40 -25 12 0 15 -16 15 -98
l0 -97 -100 -100 -100 -100 0 93 0 92 30 0 c19 0 42 11 62 29 27 24 33 36 33
71 0 35 -6 47 -33 71 -44 40 -93 40 -133 0 -33 -33 -40 -93 -14 -127 11 -15
14 -53 15 -170 l0 -150 -32 -30 c-74 -68 -68 -91 -68 241 l0 298 101 102 100
102 0 162 c-1 128 2 161 12 161 23 0 47 35 47 68 0 63 -80 81 -116 27 -14 -21
-14 -30 -4 -54 6 -16 18 -32 26 -35 11 -4 14 -38 14 -169 l0 -164 -87 -83
c-82 -77 -88 -82 -91 -58 -2 15 -29 50 -67 88 l-65 64 0 70 0 70 60 61 61 61
0 205 0 204 32 20 c24 15 33 29 35 54 7 70 -78 109 -126 58 -31 -33 -28 -73 8
-107 l30 -28 0 -199 0 -199 -50 -49 -50 -49 0 145 0 145 -75 74 -75 74 0 78
c0 59 3 78 14 78 7 0 25 12 40 26 19 20 26 37 26 66 0 52 -15 77 -55 94 -41
17 -42 17 -82 -1z m87 -50 c20 -21 22 -30 14 -52 -5 -15 -21 -34 -35 -42 -24
-13 -29 -13 -53 2 -37 25 -44 62 -15 93 29 31 59 31 89 -1z m274 -111 c51 -50
-23 -125 -76 -77 -36 33 -13 93 37 93 13 0 31 -7 39 -16z m-794 -224 c24 -24
25 -38 4 -68 -18 -26 -50 -28 -76 -5 -22 20 -23 51 -1 75 21 24 48 23 73 -2z
m1682 -147 c41 -37 3 -111 -50 -98 -50 12 -68 61 -35 97 20 22 61 23 85 1z
m-684 -5 c29 -29 7 -78 -35 -78 -35 0 -52 43 -28 70 20 23 45 26 63 8z m156
-524 c9 -8 16 -28 16 -44 0 -36 -23 -60 -57 -60 -55 0 -83 62 -46 102 20 22
66 24 87 2z m-802 -171 c39 -35 8 -109 -43 -101 -12 2 -31 15 -42 29 -16 23
-17 30 -7 50 23 43 59 52 92 22z m638 -35 c13 -25 13 -31 0 -55 -31 -56 -113
-36 -113 27 0 63 82 83 113 28z m-346 -349 c35 -28 35 -70 -1 -98 -30 -24 -58
-20 -85 11 -25 28 -22 55 7 83 29 30 45 31 79 4z m231 -1791 c46 -21 46 -95 0
-116 -33 -15 -72 0 -87 33 -18 40 5 78 59 94 1 1 14 -4 28 -11z m-313 -385
c38 -34 11 -103 -41 -103 -32 0 -44 8 -60 38 -12 23 -1 58 23 74 19 13 59 9
78 -9z m618 -23 c24 -24 25 -48 4 -78 -29 -42 -104 -18 -104 35 0 28 33 63 60
63 11 0 29 -9 40 -20z m-804 -158 c24 -16 35 -51 23 -74 -15 -29 -28 -38 -58
-38 -53 0 -80 62 -44 102 16 18 59 24 79 10z m-528 -538 c26 -18 30 -65 6 -88
-23 -24 -70 -20 -88 6 -22 31 -20 45 9 73 28 29 42 31 73 9z m672 -19 c15 -19
6 -61 -16 -69 -27 -10 -53 3 -60 30 -8 30 11 54 41 54 12 0 28 -7 35 -15z
m1001 -135 c13 -7 19 -21 19 -45 0 -35 -20 -55 -54 -55 -41 0 -64 79 -28 99
22 13 39 13 63 1z m-796 -216 c9 -4 21 -18 26 -32 21 -55 -48 -96 -93 -55 -46
42 6 110 67 87z m280 -106 c46 -21 47 -83 2 -108 -24 -13 -30 -13 -54 0 -23
13 -28 23 -28 55 0 32 5 42 25 52 30 15 24 15 55 1z"
          />
          <path fill={PRIMARY_LIGHT} d="M7514 5710 c0 -52 1 -74 3 -47 2 26 2 68 0 95 -2 26 -3 4 -3 -48z" />
          <path fill={PRIMARY_LIGHT} d="M7725 5750 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
          <path
            fill={PRIMARY_LIGHT}
            d="M6785 5440 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
          />
          <path
            fill={PRIMARY_LIGHT}
            d="M6890 5237 c0 -1 35 -36 78 -77 l77 -75 -75 78 c-69 71 -80 82 -80
74z"
          />
          <path fill={PRIMARY_LIGHT} d="M6934 5008 l-19 -23 23 19 c12 11 22 21 22 23 0 8 -8 2 -26 -19z" />
          <path
            fill={PRIMARY_LIGHT}
            d="M6660 4966 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
          />
          <path fill={PRIMARY_LIGHT} d="M6508 4903 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
          <path
            fill={PRIMARY_LIGHT}
            d="M6580 4907 c0 -2 15 -16 33 -33 l32 -29 -29 33 c-28 30 -36 37 -36
29z"
          />
          <path fill={PRIMARY_LIGHT} d="M7865 4788 l-60 -63 63 60 c58 55 67 65 59 65 -1 0 -29 -28 -62 -62z" />
          <path fill={PRIMARY_LIGHT} d="M7934 4788 l-19 -23 23 19 c12 11 22 21 22 23 0 8 -8 2 -26 -19z" />
          <path fill={PRIMARY_LIGHT} d="M7820 4673 l-65 -68 68 65 c37 35 67 66 67 67 0 8 -10 -2 -70 -64z" />
          <path
            d="M745 3956 c-116 -28 -202 -92 -252 -188 -25 -47 -28 -64 -28 -148 0
-84 3 -102 28 -152 46 -94 108 -129 320 -179 269 -63 313 -81 337 -139 27 -65
-25 -147 -108 -170 -50 -14 -196 -13 -245 2 -57 17 -104 65 -119 121 l-12 47
-114 0 -115 0 7 -52 c28 -212 238 -342 523 -324 203 13 331 89 395 233 32 73
30 194 -6 267 -50 103 -113 137 -361 196 -228 55 -260 66 -291 103 -22 26 -25
38 -21 74 6 53 28 79 87 106 57 26 176 27 237 2 53 -21 100 -73 109 -120 l6
-35 115 0 116 0 -7 43 c-25 160 -127 266 -301 312 -70 18 -228 19 -300 1z"
          />
          <path
            fill={PRIMARY_LIGHT}
            d="M5000 3370 l0 -570 50 0 50 0 0 513 c1 423 3 508 14 487 7 -14 93
-245 192 -513 l179 -488 48 3 49 3 186 507 187 507 5 -507 5 -507 50 0 50 0 3
568 2 568 -81 -3 -82 -3 -168 -460 c-219 -598 -200 -550 -209 -534 -4 8 -90
236 -190 507 l-183 492 -79 0 -78 0 0 -570z"
          />
          <path
            fill={PRIMARY_LIGHT}
            d="M8530 3870 c0 -68 1 -70 25 -70 23 0 25 -3 25 -49 0 -38 -6 -56 -25
-79 -14 -17 -25 -42 -25 -57 l0 -27 32 19 c59 34 73 73 77 211 l3 122 -56 0
-56 0 0 -70z"
          />
          <path
            d="M4478 3753 l-3 -118 -57 -3 -58 -3 0 -74 0 -75 60 0 60 0 0 -285 c0
-329 2 -340 72 -377 36 -18 63 -23 161 -26 l117 -4 0 86 0 86 -52 0 c-87 0
-83 -14 -83 267 l0 248 68 3 67 3 0 74 0 74 -67 3 -68 3 -3 118 -3 117 -104 0
-104 0 -3 -117z"
          />
          <path
            d="M1884 3642 c-49 -20 -71 -36 -107 -80 l-27 -34 0 56 0 56 -110 0
-110 0 0 -420 0 -420 115 0 115 0 0 290 c0 320 3 335 60 365 35 18 109 20 145
3 56 -25 59 -46 63 -365 l4 -293 109 0 109 0 0 283 c0 249 2 286 18 316 26 51
60 72 119 73 59 0 86 -12 111 -49 15 -24 17 -61 20 -325 l3 -298 110 0 109 0
0 323 c0 191 -4 337 -10 359 -52 185 -319 240 -464 94 -34 -33 -51 -45 -54
-35 -10 30 -88 97 -127 108 -58 16 -154 13 -201 -7z"
          />
          <path
            d="M3130 3645 c-66 -19 -120 -53 -153 -97 -28 -37 -57 -116 -57 -153 0
-25 0 -25 103 -25 l102 0 16 39 c24 55 60 76 134 76 76 0 120 -18 136 -56 15
-38 1 -72 -38 -93 -16 -8 -81 -22 -145 -30 -129 -18 -184 -35 -240 -71 -109
-72 -131 -269 -43 -373 53 -61 119 -85 224 -80 92 3 139 21 213 79 40 32 42
31 52 -23 l7 -38 119 0 c105 0 120 2 120 16 0 9 -8 23 -17 30 -16 11 -19 42
-24 321 -6 342 -8 354 -72 410 -60 53 -136 75 -267 79 -82 3 -131 -1 -170 -11z
m285 -531 c-10 -100 -62 -157 -157 -174 -85 -16 -148 28 -148 102 0 60 45 101
130 117 36 7 90 20 120 29 65 20 65 20 55 -74z"
          />
          <path
            d="M4185 3645 c-38 -13 -90 -61 -127 -115 -17 -25 -17 -24 -17 43 l-1
67 -105 0 -105 0 0 -420 0 -420 110 0 110 0 0 243 c1 252 7 294 47 339 31 35
93 58 161 58 l62 0 0 110 0 110 -47 0 c-27 -1 -66 -7 -88 -15z"
          />
          <path
            fill={PRIMARY_LIGHT}
            d="M6529 3627 c-175 -50 -269 -197 -269 -423 0 -262 146 -420 385 -418
65 0 92 6 140 28 146 66 225 210 225 405 -1 247 -136 407 -353 417 -49 2 -101
-1 -128 -9z m193 -77 c21 -6 57 -25 80 -43 139 -106 154 -417 27 -561 -53 -60
-101 -81 -194 -81 -70 0 -87 4 -133 30 -88 48 -138 141 -149 277 -15 171 63
330 180 372 50 17 137 20 189 6z"
          />
          <path
            fill={PRIMARY_LIGHT}
            d="M7863 3625 c-104 -32 -176 -96 -224 -200 -50 -105 -61 -221 -35 -346
36 -170 149 -277 310 -294 68 -8 173 8 221 32 76 39 152 131 170 206 7 27 7
27 -43 27 -47 0 -50 -2 -55 -30 -8 -38 -69 -105 -121 -133 -57 -30 -183 -30
-242 0 -86 45 -154 160 -154 258 l0 45 315 0 315 0 0 53 c0 124 -52 250 -129
318 -77 69 -223 97 -328 64z m222 -93 c79 -40 133 -125 137 -218 l3 -49 -267
-3 -268 -2 0 23 c0 42 29 123 59 164 34 46 78 80 130 100 50 19 156 11 206
-15z"
          />
          <path
            fill={PRIMARY_LIGHT}
            d="M9012 3623 c-66 -23 -116 -62 -143 -115 -29 -57 -24 -168 9 -215 32
-45 127 -88 261 -117 183 -41 231 -74 231 -162 0 -110 -131 -176 -284 -144
-74 15 -129 62 -153 131 -17 48 -18 49 -60 49 -50 0 -53 -9 -27 -82 33 -97
130 -170 245 -183 74 -9 189 9 249 40 165 84 169 293 8 376 -21 11 -98 35
-170 55 -198 52 -249 92 -235 180 12 76 60 113 157 121 122 11 230 -46 246
-131 6 -35 8 -36 51 -36 l45 0 -7 37 c-16 84 -67 147 -150 184 -68 30 -200 36
-273 12z"
          />
          <path
            fill={PRIMARY_LIGHT}
            d="M7410 2256 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
          />
          <path fill={PRIMARY_LIGHT} d="M6739 2103 l-24 -28 28 24 c15 14 27 26 27 28 0 8 -8 1 -31 -24z" />
          <path fill={PRIMARY_LIGHT} d="M6461 2054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path fill={PRIMARY_LIGHT} d="M6645 2008 l-40 -43 43 40 c39 36 47 45 39 45 -2 0 -21 -19 -42 -42z" />
          <path fill={PRIMARY_LIGHT} d="M8058 1833 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
          <path fill={PRIMARY_LIGHT} d="M7933 1725 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z" />
          <path
            fill={PRIMARY_LIGHT}
            d="M8090 1665 c35 -36 67 -65 69 -65 3 0 -24 29 -59 65 -35 36 -67 65
-69 65 -3 0 24 -29 59 -65z"
          />
          <path
            fill={PRIMARY_LIGHT}
            d="M7554 1615 c-1 -60 1 -95 3 -76 l4 34 75 -74 74 -73 2 -61 2 -60 3
59 4 59 -80 81 -80 81 -4 70 c-2 43 -3 27 -3 -40z"
          />
          <path fill={PRIMARY_LIGHT} d="M7633 1675 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z" />
          <path fill={PRIMARY_LIGHT} d="M7581 1234 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path fill={PRIMARY_LIGHT} d="M7130 1173 l-55 -58 58 55 c31 30 57 56 57 57 0 8 -10 -1 -60 -54z" />
          <path fill={PRIMARY_LIGHT} d="M6699 1173 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
          <path fill={PRIMARY_LIGHT} d="M7801 1164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
